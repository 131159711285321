<template>
  <div class="smart-farm">
    <!-- 导航栏 -->
    <Nav :isHome="false"></Nav>
    <CommonNav pageName="智慧农业"></CommonNav>
    <section>
      <img class="smart-farm-bg" src="../../assets/images/smartFarm/bg.png" alt="">
    </section>
    <section class="smart-farm-container">
      <p class="smart-farm-head">与哈尔滨工业大学人工智能研究院及东北林业大学人工智能实验室，针对数字农业项目开展产学研合作，共同针对数字农业模型、粮食烘干系统等进行针对性的研发，旨在重点解决农产品在产量及质量上的关键性问题。</p>
      <h4>数字农业</h4>
      <p class="smart-farm-article">联合农业、气象、水利、环保、自然资源、商务等政府部门，打造全面联通汇集和智能计算分析的农业农村大数据平台，形成关联融合、应用广泛、价值更高的全数据平台。农业农村大数据平台将按照“1+2+3+N”的总体设计，以农业农村大数据平台为“一主体”、以数字农业和数字乡村为“两方向”，以建设农业农村数据汇集中心、运算中心和服务中心为三大功能定位，以高标准农田建设、黑土保护、种植结构优化、农田节水灌溉、农产品溯源、病虫害监测等作为应用服务，推动大数据在农业产业发展、政府管理和乡村治理等方面的广泛深入应用，为推进乡村振兴提供全面的数据支撑。</p>
      <h5 class="smart-farm-title">核心技术及创新点包括：</h5>
      <h5>（1）多维异构数据协同分析技术</h5>
      <p class="smart-farm-article">结合人工智能、云计算、大数据处理分析技术等，研发多维异构数据协同分析与共性技术，集成天空地一体化数据存储与管理系统；</p>
      <h5>（2）多参数协同农作物产量模型构建</h5>
      <p class="smart-farm-article">综合遥感、气象、作物、土壤等多源时空大数据及一体化智能观测网技术，通过点面数据融合实现时空多维拓展，构建多参数协同农作物产量模型，生成模拟农作物产量的产量预估模型，并利用实测产量数据进行校准。</p>
      <h5>（3）采用区域大数据管理进行数据分析，形成智能决策系统。</h5>
      <h4>智能粮食烘干系统</h4>
      <p class="smart-farm-article">为满足种粮大户、家庭农场、农业合作社及粮食加工企业对节能高效粮食烘干技术的迫切需求，公司面向粮食加工领域的烘干环节，采用云计算、传感器、人工智能与计算机视觉三大核心技术，开发智能粮食烘干系统，该系统能够实现质量控制、故障报警、智能分析、数据诊断、精准定位等服务，大幅度提高作业效率、节省燃料、提高粮食品质，为用户带来更加客观的经济效益。</p>
      <h5 class="smart-farm-title">核心技术及创新点：</h5>
      <p class="smart-farm-article">（1）采用温湿度传感器：设备安装部署多个温度采集节点、湿度采集节点。采用高精度温度传感器芯片和热电偶，获取环境温度、塔温、炉温等，采用高频电容原理，采集进粮、出粮湿度。上述温湿度通过总线汇总至工控机；</p>
      <p class="smart-farm-article">（2）仪表识别模型：采用多个高清摄像头，拍摄控制柜上多种仪表，运用目标识别和OCR技术，实现仪表值的采集。无需改动控制柜，无需人工输入，无损安装监测。</p>
      <p class="smart-farm-article">（3）粮食烘干多参数校准模型：整合气候数据、地理数据、环境温湿度、塔温、风温、作物品种、进出粮温度、电容等多个参数，共同校准和标定出粮湿度传感器。提高水分在线测量精度，提高粮食烘干质量。</p>
    </section>
    <section class="smart-farm-footer">
      <img src="../../assets/images/smartFarm/imgFirst.png" alt="">
      <img src="../../assets/images/smartFarm/imgSecond.png" alt="">
    </section>
     <!-- 底部 -->
     <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/nav.vue'
import CommonNav from '@/components/commonNav.vue'
import Footer from '@/components/footer.vue'

export default {
  data() {
    return {

    }
  },
  components: {
    Nav,
    CommonNav,
    Footer
  },
  beforeDestroy(){
    window.scrollTo(0,0)
  }
}
</script>

<style lang="scss" scoped>
.smart-farm{
  width: 100vw;
  padding-top: 120px;
}
.smart-farm-bg {
  width: 100%;
  height: 100%;
}
.smart-farm-container{
  width: 55%;
  max-width: 1057px;
  margin: 106px auto 126px;
  font-size: 22px;
  color: #808080;
  line-height: 2;
  text-align: left;
  // .smart-farm-head{
  //   margin-bottom: 20px;
  // }
  h4{
    height: 45px;
    font-size: 30px;
    color: #202020;
    font-weight: 500;
    padding-left: 10px;
    border-left: 3px solid #C7020A;
    margin-top: 30px;
    line-height: 45px;
  }
  .smart-farm-article{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    // text-align-last: justify;
    /*兼容ie*/
    text-justify: distribute-all-lines;
  }
  .smart-farm-title{
    color: #C7050F;
    margin-bottom: 20px;
    font-weight: 500;
  }
  h5{
    font-weight: 400;
    font-size: 22px;
  }
}
.smart-farm-footer{
  width: 55%;
  max-width: 1057px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto 171px;
  img{
    flex: 1;
  }
}
.smart-farm-footer img:nth-child(1){
  width: 26.9%;
  margin-right: 20px;
}
.smart-farm-footer img:nth-child(2){
  width: 26.5%;
}
</style>